<template>
  <v-navigation-drawer
    :value="isAddActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New </span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="AddonData.Name"
            outlined
            dense
            :rules="[validators.required]"
            label="Name"
            placeholder="Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            v-model="AddonData.Description"
            outlined
            dense
            label="Description"
            placeholder="Description"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <br />
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetAddonData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { AddBranchAddon } from '@core/api/Addons'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isAddActive',
    event: 'update:is-add-active',
  },
  props: {
    isAddActive: {
      type: Boolean,
      required: true,
    },
    Addons: { type: Array, required: true },
    BranchId: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const blankAddonData = {
      Id: props.Addons.length + 1,
      Name: '',
      disabled: false,
      IsRequired: false,
      IsMulti: false,
      IsDependence: false,
      Description: '',
      Options: [],
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const AddonData = ref(JSON.parse(JSON.stringify(blankAddonData)))
    const resetAddonData = () => {
      AddonData.value = JSON.parse(JSON.stringify(blankAddonData))
      resetForm()
      emit('update:is-add-active', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        AddonData.value.Id = props.Addons.length + 1
        let AddonsList = props.Addons
        AddonsList.push(AddonData.value)

        AddBranchAddon({ BranchId: props.BranchId, Addon: JSON.stringify(AddonsList) }).then(() => {
          emit('refetch-data')
          emit('update:is-add-active', false)
        })
        resetAddonData()
      } else {
        validate()
      }
    }

    return {
      resetAddonData,
      form,
      onSubmit,
      AddonData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
