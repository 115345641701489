<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="10">
          <v-btn right color="primary" class="mb-4 me-3" @click.stop="isAddActive = !isAddActive">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Addon</span>
          </v-btn>
        </v-col>
        <v-col cols="2"><vc-menu-add-list :BranchId="parseInt($router.currentRoute.params.id)" /></v-col>
      </v-row>
      <addon-add-new
        @refetch-data="fetchAddons"
        v-model="isAddActive"
        :Addons="AddonListTable"
        :BranchId="parseInt($router.currentRoute.params.id)"
      ></addon-add-new>
      <addon-delete
        @refetch-data="fetchAddons"
        :BranchId="parseInt($router.currentRoute.params.id)"
        :Addons="AddonListTable"
        :Id="addonTemp.Id"
        v-model="isDialogDelete"
      />
      <addon-edit
        @refetch-data="fetchAddons"
        :BranchId="parseInt($router.currentRoute.params.id)"
        :Addon="addonTemp"
        :Addons="AddonListTable"
        v-model="isDialogEdit"
      />

      <!-- Main Card -->
      <app-card-actions @refresh="fetchAddons">
        <template slot="title"> Addons List </template>
        <v-row class="px-2 ma-0">
          <v-col cols="12" sm="4">
            <!-- search -->
            <v-text-field v-model="searchQuery" placeholder="Search" outlined hide-details dense> </v-text-field>

            <v-spacer></v-spacer>
          </v-col>
        </v-row>
        <v-card-text>
          <v-data-table
            :headers="tableColumns"
            :items="
              AddonListTable.filter(data => !searchQuery || data.Name.toLowerCase().includes(searchQuery.toLowerCase()))
            "
            :options.sync="options"
            :server-items-length="totalAddonListTable"
            :loading="loading"
          >
            <!-- Id -->
            <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
            <!-- Actions -->
            <template #[`item.Actions`]="{ item }">
              <div class="demo-space-x">
                <v-btn icon color="error" @click.stop=";(isDialogDelete = !isDialogDelete), (addonTemp = { ...item })">
                  <v-icon small>
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                </v-btn>
                <v-btn icon color="primary" @click.stop=";(isDialogEdit = !isDialogEdit), (addonTemp = { ...item })">
                  <v-icon small> {{ icons.mdiPencilOutline }} </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-text></app-card-actions
      >
    </v-col></v-row
  >
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import VcMenuAddList from '@/components/vc-menu-add-list/VcMenuAddList.vue'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AddonAddNew from './AddonAddNew.vue'
import AddonDelete from './AddonDelete.vue'
import AddonEdit from './AddonEdit.vue'
import useAddonList from './useAddonList'

export default {
  components: {
    AddonAddNew,
    AddonDelete,
    AddonEdit,
    VcMenuAddList,
    AppCardActions,
  },
  setup() {
    const isAddActive = ref(false)
    const isDialogDelete = ref(false)
    const isDialogEdit = ref(false)
    const {
      AddonListTable,
      tableColumns,
      searchQuery,
      totalAddonListTable,
      options,
      AddonTotalLocal,
      fetchAddons,
      loading,
    } = useAddonList()
    let addonTemp = ref({ Id: 0 })

    return {
      addonTemp,
      AddonListTable,
      isAddActive,
      isDialogDelete,
      isDialogEdit,
      tableColumns,
      searchQuery,
      totalAddonListTable,
      options,
      AddonTotalLocal,
      fetchAddons,
      loading,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
