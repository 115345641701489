import router from '@/router'
import { GetAddonsByBranch } from '@core/api/Addons'
import { ref, watch } from '@vue/composition-api'

export default function useAddonList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'Description', value: 'Description', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const AddonListTable = ref([])
  const searchQuery = ref('')
  const BranchId = ref(router.currentRoute.params.id)
  const statusFilter = ref(null)
  const totalAddonListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const loading = ref(false)

  const AddonTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchAddons = () => {
    GetAddonsByBranch({ BranchId: BranchId.value })
      .then(response => {
        // handle success
        //  console.log('addons res', response)
        response == '' ? (AddonListTable.value = []) : (AddonListTable.value = response)
        totalAddonListTable.value = AddonListTable.lenght
        AddonTotalLocal.value = AddonListTable.lenght
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        // remove loading state
        loading.value = false
        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true

    fetchAddons()
  })
  return {
    AddonListTable,
    tableColumns,
    searchQuery,
    BranchId,
    statusFilter,
    totalAddonListTable,
    options,
    AddonTotalLocal,
    fetchAddons,
  }
}
