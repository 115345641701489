<template>
  <v-dialog :value="isDialogDelete" max-width="500px" @input="val => $emit('update:is-dialog-delete', val)">
    <v-card>
      <v-card-title> Are you sure you want to delete this item? </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" outlined @click="closeDelete"> Cancel </v-btn>
        <v-btn color="success" @click="deleteItemConfirm"> OK </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <v-snackbar :light="$vuetify.theme.dark" v-model="isFormSubmittedSnackbarVisible"> Can't Delete . </v-snackbar>
    </v-card>
  </v-dialog>
</template>

<script>
import { AddBranchAddon } from '@core/api/Addons'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isDialogDelete',
    event: 'update:is-dialog-delete',
  },
  props: {
    isDialogDelete: {
      type: Boolean,
      required: true,
    },
    Id: { type: Number, required: true },
    Addons: { type: Array, required: true },
    BranchId: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const isFormSubmittedSnackbarVisible = ref(false)

    const deleteItemConfirm = () => {
      let AddonsList = [...props.Addons]
      AddonsList.splice(
        AddonsList.findIndex(f => f.Id == props.Id),
        1,
      )
      //  console.log(AddonsList, AddonData.value)
      AddBranchAddon({
        BranchId: props.BranchId,
        Addon: JSON.stringify(AddonsList),
      }).then(res => {
        if (res) {
          emit('refetch-data')
          emit('update:is-dialog-delete', false)
        } else {
          isFormSubmittedSnackbarVisible.value = true
        }
      })
    }
    const closeDelete = () => {
      emit('update:is-dialog-delete', false)
    }

    return {
      deleteItemConfirm,
      closeDelete,
      isFormSubmittedSnackbarVisible,
    }
  },
}
</script>
