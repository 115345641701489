<template>
  <v-dialog :value="isDialogEdit" max-width="750px" @input="val => $emit('update:is-dialog-edit', val)">
    <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Addon : {{ Addon.Id }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="app-item-preview px-4 ma-0">
              <v-col cols="8" md="4">
                <v-text-field
                  v-model="AddonData.Name"
                  outlined
                  dense
                  :rules="[validators.required]"
                  label="Name"
                  placeholder="Name"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="8" md="4">
                <v-text-field
                  v-model="AddonData.Description"
                  outlined
                  dense
                  label="Description"
                  placeholder="Description"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="close"> Cancel </v-btn>
          <v-btn color="success" type="submit"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { AddBranchAddon } from '@core/api/Addons'
import { lengthValidator, required } from '@core/utils/validation'
import { mdiClockTimeFourOutline, mdiClose, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
export default {
  model: {
    prop: 'isDialogEdit',
    event: 'update:is-dialog-edit',
  },
  props: {
    isDialogEdit: {
      type: Boolean,
      required: true,
    },
    Addon: { type: Object, required: true },
    Addons: { type: Array, required: true },
    BranchId: { type: Number, required: true },
  },
  watch: {
    Addon(New) {
      this.AddonData = New
    },
  },
  setup(props, { emit }) {
    const blankData = {
      Id: undefined,
      Name: '',
      disabled: false,
      IsRequired: false,
      IsMulti: false,
      IsDependence: false,
      Description: '',
      Options: [],
    }

    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const AddonData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetAddonData = () => {
      AddonData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      emit('update:is-dialog-edit', false)
    }

    const onSubmit = () => {
      if (valid.value) {
        let AddonsList = [...props.Addons]
        AddonsList.splice(
          AddonsList.findIndex(f => f.Id == AddonData.value.Id),
          1,
          AddonData.value,
        )
        //  console.log(AddonsList, AddonData.value)
        AddBranchAddon({
          BranchId: props.BranchId,
          Addon: JSON.stringify(AddonsList),
        }).then(() => {
          emit('refetch-data')
          emit('update:is-add-active', false)
        })
        resetAddonData()
      } else {
        validate()
      }
    }
    const close = () => {
      resetAddonData()
    }

    return {
      resetAddonData,
      form,
      blankData,
      onSubmit,
      AddonData,
      valid,
      validate,
      mdiPlus,
      close,
      // validation
      validators: { required, lengthValidator },
      icons: {
        mdiClose,
        mdiClockTimeFourOutline,
      },
    }
  },
}
</script>
